import classNames from "classnames";
import classes from "./Message.module.css";

const Message = ({ className, message, type }) => {
    return (
        <div className={classNames(classes.root, classes[type], className)}>
            <span>{message}</span>
        </div>
    )
};

export default Message;