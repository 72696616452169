import { useLocation } from "react-router-dom";

const useCms = () => {
    const { pathname } = useLocation();
    const pages = window.a3zCms || {};

    return pages.hasOwnProperty(pathname)
        ? pages[pathname]
        : null;
};

export default useCms;