import { useState } from "react";

import api from "../../lib/api";
import { t } from "../../lib/i18n";

const useStatus = () => {
    const [ status, setStatus ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState();

    const fetchStatus = async () => {
        setLoading(true);

        api.get('/V1/config/status')
            .then(response => {
                setStatus(!!response.data?.enabled);
                setLoading(false);
                setError('');
            })
            .catch(error => {
                console.warn(error);
                setLoading(false);
                setError(t('Something went wrong, online subscription is not available. Please try again later'));
            });
    };

    return {
        loading,
        error,
        status,
        fetchStatus
    }
};

export default useStatus;
