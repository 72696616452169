import { Link } from 'react-router-dom';
import classNames from "classnames";

import { t } from '../../lib/i18n';

import classes from './Footer.module.css';

import logo from '../../assets/images/logo_light.png';

const Footer = ({ type = 'auto' }) => {
    const isFixed = (type === 'fixed');

    if (isFixed) {
        return (
            <div className={classNames(classes.root, classes.fixed)}>
                <div className={classes.wrapper}>
                    <div className={classes.logo}>
                        <img src={logo} height="32px" width="90px" alt="A3Z" />
                    </div>
                    <div className={classes.linksWrapper}>
                        <span className={classes.link}><Link to="/cookie-statement">{t('Cookie Statement')}</Link></span>
                        <span className={classes.separator}>|</span>
                        <span className={classes.link}><Link to="/privacy-statement">{t('Privacy Statement')}</Link></span>
                        <span className={classes.separator}>|</span>
                        <span className={classes.link}><Link to="/faq">{t('FAQ')}</Link></span>
                    </div>
                    <div className={classes.copyright}>
                        <span>&copy; A3Z {new Date().getFullYear()}</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.logo}>
                    <img src={logo} height="32px" width="90px" alt="A3Z" />
                </div>
                <div className={classes.linksWrapper}>
                    <div className={classes.links}>
                        <span className={classes.linkTitle}>Contact</span>
                        <span className={classes.link}>
                            <a href="https://www.facebook.com/avondwandeldriedaagse.zoeterwoude"
                               rel="noreferrer"
                               target="_blank">Facebook</a>
                        </span>
                        <span className={classes.link}>
                            <a href="https://twitter.com/A3DZwoude"
                               target="_blank"
                               rel="noreferrer">Twitter</a>
                        </span>
                        <span className={classes.link}>
                            <a href="mailto:a3zoeterwoude@gmail.com">Email</a>
                        </span>
                    </div>
                    <div className={classes.links}>
                        <span className={classes.linkTitle}>Info</span>
                        <span className={classes.link}><Link to="/cookie-statement">{t('Cookie Statement')}</Link></span>
                        <span className={classes.link}><Link to="/privacy-statement">{t('Privacy Statement')}</Link></span>
                        <span className={classes.link}><Link to="/faq">{t('FAQ')}</Link></span>
                    </div>
                </div>
            </div>
            <div className={classes.copyright}>
                <span>&copy; Stichting Avond 3 Daagse Zoeterwoude {new Date().getFullYear()}</span>
            </div>
        </div>
    );
}

export default Footer;
