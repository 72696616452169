import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import uniqid from 'uniqid';

import { getPath, t } from "../../../lib/i18n";

import { Button, Field, Form, Input, Radio } from "../../../components/ui/form";

import CheckoutContext from "../../../context/checkout";

import checkoutClasses from "../Checkout.module.css";
import classes from "./Participants.module.css";

const Participants = () => {
    const navigate = useNavigate();
    const { data, addData, config = {} } = useContext(CheckoutContext);
    const { participants = [] } = data;

    const { allowed_distances = [] } = config;

    const getId = (participant) => (participant.id ?? participant.uid);

    const updateParticipants = (newData) => {
        addData({
            participants: newData
        });
    };

    const onAdd = () => {
        updateParticipants([
            ...participants,
            {
                uid: uniqid('__')
            }
        ]);
    };

    const onChange = (participant, field, { target: { value }}) => {
        const newData = { ...participant };
        newData[field] = value;

        const index = participants.findIndex((p) => (getId(participant) === getId(p)));

        let newParticipants = [ ...participants ];
        newParticipants[index] = newData;

        updateParticipants(newParticipants);
    };

    const onRemove = (id) => {
        updateParticipants(
            participants.filter((participant) => getId(participant) !== id)
        );
    };

    const goBack = () => {
        navigate(getPath('/checkout/details'));
    };

    const onSubmit = ({ valid }) => {
        if (valid) {
            navigate(getPath('/checkout/confirm'));
        }
    };

    const getDistanceOptions = (participant) => (
        allowed_distances.sort().map((distance) => (
            <Radio
                key={distance}
                name={"distance." + getId(participant)}
                value={distance}
                checked={!!participant.distance && (String(participant.distance) === String(distance))}
                onChange={e => onChange(participant, 'distance', e)}
                required
            >{distance}km</Radio>
        ))
    );

    const participantFieldSets = participants.map((participant) => {
        const id = getId(participant);
        const {
            first_name = '',
            middle_name = '',
            last_name = '',
            has_medal = '',
            is_main = false,
        } = participant;

        return (
            <div key={id} className={classes.participant}>
                <div className={classes.name}>
                    <Input
                        name={"first_name." + id}
                        label={t('Firstname')}
                        value={first_name}
                        disabled={is_main}
                        onChange={e => onChange(participant, 'first_name', e)}
                        required />
                    <Input
                        name={"middle_name." + id}
                        label={t('Middlename')}
                        value={middle_name}
                        disabled={is_main}
                        onChange={e => onChange(participant, 'middle_name', e)} />
                    <Input
                        name={"last_name." + id}
                        label={t('Lastname')}
                        value={last_name}
                        disabled={is_main}
                        onChange={e => onChange(participant, 'last_name', e)}
                        required />
                </div>
                <Field label={t('Distance')}>
                    {getDistanceOptions(participant)}
                </Field>
                <Field label={t('Medal')}>
                    <Radio
                        name={"has_medal." + id}
                        value="1"
                        checked={has_medal === '1' || has_medal === true}
                        onChange={e => onChange(participant, 'has_medal', e)}
                        required
                    >{t('Yes')}</Radio>
                    <Radio
                        name={"has_medal." + id}
                        value="0"
                        checked={has_medal === '0' || has_medal === false}
                        onChange={e => onChange(participant, 'has_medal', e)}
                        required
                    >{t('No')}</Radio>
                </Field>
                {
                    !is_main && (
                        <div>
                            <Button
                                onClick={() => onRemove(id)}
                                variant="secondary"
                            >{t('checkout.participants.remove')}</Button>
                        </div>
                    )
                }
            </div>
        );
    });

    useEffect(() => {
        const requiredData = [
            'first_name',
            'last_name',
            'email'
        ];

        requiredData.forEach((attr) => {
            if (!data[attr]) {
                navigate(getPath('/checkout/details'));
            }
        });

        if (!data.participants?.length) {
            updateParticipants([{
                uid: uniqid('__'),
                first_name: data.first_name,
                middle_name: data.middle_name,
                last_name: data.last_name,
                is_main: true
            }]);
        }
    }, []);

    return (
        <Form onSubmit={onSubmit} className={checkoutClasses.stepRoot}>
            <div className={checkoutClasses.stepContent}>
                <div>
                    <h2>{t('checkout.participants.title')}</h2>
                    <p>{t('checkout.participants.description')}</p>
                </div>
                <div>
                    <div className={classes.participants}>
                        {participantFieldSets}
                    </div>
                    <div className={classes.addBtn} onClick={onAdd}>
                        <span>{t('checkout.participants.add')}</span>
                    </div>
                </div>
            </div>
            <div className={checkoutClasses.stepButtons}>
                <Button onClick={goBack} variant="secondary">{t('checkout.steps.previous')}</Button>
                <Button type="submit" variant="primary">{t('checkout.steps.next')}</Button>
            </div>
        </Form>
    );
};

export default Participants;
