import useViews from "../../hooks/useViews";

const Price = ({ className = '', price, currency }) => {
    const { formatPrice } = useViews();
    const formattedPrice = formatPrice(price, currency);

    return (
        <span className={className}>{formattedPrice}</span>
    )
};

export default Price;