import classNames from "classnames";
import classes from "./Loader.module.css";

const Loader = ({ border, color, size, className }) => {
    const rootStyle = {};
    const divStyle = {};

    if (border) {
        divStyle.borderWidth = border;
    }

    if (size) {
        rootStyle.height = size;
        rootStyle.width = size;
        divStyle.height = size;
        divStyle.width = size;
    }

    if (color) {
        rootStyle.color = color;
    }

    return (
        <div className={classNames(classes.root, className)} style={rootStyle}>
            <div style={divStyle}></div>
            <div style={divStyle}></div>
            <div style={divStyle}></div>
            <div style={divStyle}></div>
        </div>
    );
};

export default Loader;
