import { useState } from "react";

import api from "../../../lib/api";
import { t } from "../../../lib/i18n";
import useSessionId from "./useSessionId";

const useConfirmSubscription = () => {
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState();
    const { getSessionId } = useSessionId();

    const confirmSubscription = async (subscription, paymentMethod) => {
        setLoading(true);
        setError('');

        let result = null;
        let resultError = null;
        const sessionId = getSessionId();

        try {
            const response = await api.post(
                '/V1/session/confirm',
                {
                    session: {
                        session_id: sessionId,
                        subscription: subscription
                    },
                    payment_method: paymentMethod
                }
            );
            result = response?.data || null;

            return result?.redirect_url || null;
        } catch (apiError) {
            resultError = apiError;
            setError(t('Something went wrong, online subscription is not available. Please try again later'));
        }

        setLoading(false);

        return new Promise((resolve, reject) => {
            if (resultError) {
                reject(resultError);
            } else {
                resolve(result);
            }
        });
    };

    return {
        loading,
        error,
        confirmSubscription
    }
};

export default useConfirmSubscription;
