import { useRef } from "react";

import classes from "./Radio.module.css";
import classNames from "classnames";

const Radio = ({ name, children, className, labelClassName, ...restProps }) => {
    const inputRef = useRef(null);

    const onLabelClick = () => {
        inputRef.current?.click();
    }

    return (
        <div className={classNames(classes.root, className)}>
            <input type="radio" name={name} ref={inputRef} {...restProps} />
            <label htmlFor={name} className={labelClassName} onClick={onLabelClick}>{children}</label>
        </div>
    )
};

export default Radio;