import classes from './Field.module.css';

const Field = ({ children, label }) => {
    return (
        <div className={classes.root}>
            <span className={classes.label}>{label}</span>
            <div className={classes.content}>
                {children}
            </div>
        </div>
    );
};

export default Field;