import SuccessModule from "../../modules/checkout/success/Success";
import Layout from "../../modules/layout/Layout";

const Success = () => {
    return (
        <Layout type="fixed">
            <SuccessModule />
        </Layout>
    );
};

export default Success;
