import classNames from 'classnames';
import classes from './Step.module.css';

const Step = ({ children, index, active, complete, clickable, onClick }) => {
    const stepClassName = classNames(
        classes.root,
        {
            [classes.active]: active,
            [classes.complete]: complete,
            [classes.clickable]: clickable
        }
    );

    return (
        <div className={stepClassName} onClick={onClick}>
            <span className={classes.index}>{index}</span>
            <span className={classes.content}>{children}</span>
        </div>
    );
}

export default Step;