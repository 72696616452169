import { useState } from "react";

import useSessionId from "./useSessionId";

import api from "../../../lib/api";
import { t } from "../../../lib/i18n";

const useCreateOrFetchSession = () => {
    const { getSessionId, setSessionId } = useSessionId();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState();

    const createOrFetch = async () => {
        setLoading(true);
        setError('');

        let result = null;
        let resultError = null;
        let sessionId = getSessionId();

        if (sessionId === 'null') {
            sessionId = null;
        }

        try {
            const response = await api.get(
                '/V1/session/getOrCreate' + (sessionId ? ('?id=' + sessionId) : '')
            );
            result = response?.data || null;

            if (result?.session?.session_id) {
                setSessionId(result.session.session_id);
            }
        } catch (apiError) {
            resultError = apiError;
            setError(t('Something went wrong, online subscription is not available. Please try again later'));
        }

        setLoading(false);

        return new Promise((resolve, reject) => {
            if (resultError) {
                reject(resultError);
            } else {
                resolve(result);
            }
        });
    };

    return {
        loading,
        error,
        createOrFetch
    }
};

export default useCreateOrFetchSession;
