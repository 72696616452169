import { useLocation } from "react-router-dom";

import Layout from '../modules/layout/Layout';
import BuilderContent from "../components/cms/BuilderContent";

const Builder = () => {
    const { pathname } = useLocation();

    return (
        <Layout>
            <BuilderContent url={pathname} />
        </Layout>
    );
};

export default Builder;
