import classes from './Skeleton.module.css';
import classNames from "classnames";

const Skeleton = ({ className, rowClassName, rows = 1 }) => {
    const rootClass = classNames(
        classes.root,
        className
    );
    const rowClass = classNames(
        classes.loading,
        classes.row,
        rowClassName
    );

    let content = [];

    for (let i = 1; i <= rows; i++) {
        content.push((
           <p key={i} className={rowClass}>&zwnj;</p>
        ));
    }

    return (
        <div className={rootClass}>
            {content}
        </div>
    );
};

export default Skeleton;