import { useEffect, useState} from 'react';
import { Builder } from '@builder.io/react';
import DOMPurify from 'dompurify';

import Skeleton from '../../../ui/skeleton/Skeleton';
import Button from '../../../ui/form/button/Button';

import { getPath, t } from '../../../../lib/i18n';

import useStatus from "../../../../hooks/api/useStatus";

import classes from './SubscriptionParagraph.module.css';

const SubscriptionParagraph = ({ htmlTag, title, enabledText, disabledText, buttonColor, buttonTextColor, skeletonRows }) => {
    const { status: subscriptionEnabled, loading, error, fetchStatus } = useStatus();
    const btnDisabled = !subscriptionEnabled || loading;

    const Tag = htmlTag || 'h2';
    let text = '';

    if (loading) {
        text = <Skeleton rows={skeletonRows || 5} />;
    } else {
        const displayText = error ? error : (subscriptionEnabled ? enabledText : disabledText);
        text = (
            <div className={classes.text}
                 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(displayText)}} />
        );
    }

    useEffect(() => {
        fetchStatus();
    }, []);

    return (
        <div className={classes.root}>
            <Tag className={classes.title}
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(title)}} />
            {text}
            <Button
                className={classes.button}
                style={{
                    backgroundColor: buttonColor,
                    color: buttonTextColor
                }}
                disabled={btnDisabled}
                href={!btnDisabled ? getPath('/checkout') : ''}
            >{t('Subscribe')}</Button>
        </div>
    )
};

export const register = () => {
    Builder.registerComponent(SubscriptionParagraph, {
        name: "SubscriptionParagraph",
        defaultStyles: {
            marginTop: 0,
            paddingTop: '40px',
            paddingBottom: '55px',
            paddingLeft: '10%',
            paddingRight: '10%'
        },
        inputs: [
            {
                name: "title",
                type: "string",
            },
            {
                name: "enabledText",
                type: "richText",
            },
            {
                name: "disabledText",
                type: "richText",
            },
            {
                name: "htmlTag",
                type: "string",
                defaultValue: "h2",
                enum: [ "h1", "h2", "h3", "h4", "h5", "h6", "span", "strong" ]
            },
            {
                name: "skeletonRows",
                type: "number",
                defaultValue: 5
            },
            {
                name: "buttonColor",
                type: "color",
            },
            {
                name: "buttonTextColor",
                type: "color",
            },
        ],
    })
};

export default SubscriptionParagraph;