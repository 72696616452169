import { forwardRef, useImperativeHandle, useRef } from "react";

const Form = ({ children, onSubmit, ...restProps }, ref) => {
    const form = useRef(null);

    useImperativeHandle(ref, () => ({
        ref: form.current
    }));

    const submit = (event) => {
        console.log('Submitted!');
        event.preventDefault();

        const valid = form.current.checkValidity();

        form.current.classList.add('validated');

        if (onSubmit) {
            onSubmit({ valid });
        }

        return false;
    };

    return (
        <form ref={form} onSubmit={submit} noValidate {...restProps}>
            { children }
        </form>
    );
};

export default forwardRef(Form);