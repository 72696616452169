import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getPath, t } from "../../../lib/i18n";
import { filterObject } from "../../../lib/utils";

import {Button, Form, Radio} from "../../../components/ui/form";
import Message from "../../../components/ui/message/Message";
import Price from "../../../components/price/Price";

import CheckoutContext from "../../../context/checkout";

import useConfirmSubscription from "../../../hooks/api/session/useConfirmSubscription";
import useMediaQuery from "../../../hooks/useMediaQuery";

import checkoutClasses from "../Checkout.module.css";
import classes from "./Confirm.module.css";

const Confirm = () => {
    const navigate = useNavigate();
    const { match: isDesktop } = useMediaQuery('(min-width: 769px)');
    const { loading, error, confirmSubscription } = useConfirmSubscription();
    const { config = {}, data = {}, addData } = useContext(CheckoutContext);

    const { id, email, participants = [], paymentMethod } = data;
    const { payment_methods = [] } = config;

    const goBack = () => {
        navigate(getPath('/checkout/participants'));
    };

    const sanitizeParticipant = (participant) => filterObject(
        participant,
        ([key]) => key !== 'uid'
    );

    const onSubmit = ({ valid }) => {
        if (valid) {
            const subscriptionPayload = {
                ...data,
                participants: participants.map(sanitizeParticipant)
            };

            delete subscriptionPayload.paymentMethod;

            confirmSubscription(subscriptionPayload, paymentMethod.code).then((redirectUrl) => {
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                } else {
                    navigate(`${getPath('/checkout/success')}?id=${id}&email=${email}`);
                }
            });
        }
    };

    const onPaymentMethodChange = (paymentMethod) => {
        addData({
            paymentMethod
        });
    };

    const getId = (participant) => (participant.id ?? participant.uid);
    const getName = (participant) => [participant.first_name, participant.middle_name, participant.last_name]
        .filter((name) => !!name)
        .join(' ');
    const isTrue = (flag) => (flag === '1' || flag === true);

    const renderParticipant = (participant) => (
        <div key={getId(participant)} className={classes.participant}>
            <span className={classes.name}>{getName(participant)}</span>
            <span className={classes.distance}>{participant.distance}km</span>
            <span className={classes.medal}>
                {
                    isDesktop
                        ? isTrue(participant.has_medal) ? t('Yes') : t('No')
                        : isTrue(participant.has_medal) ? t('With Medal') : t('Without Medal')
                }
            </span>
        </div>
    );

    useEffect(() => {
        const requiredData = [
            'first_name',
            'last_name',
            'email'
        ];

        requiredData.forEach((attr) => {
            if (!data[attr]) {
                navigate(getPath('/checkout/details'));
            }
        });
    }, []);

    return (
        <Form onSubmit={onSubmit} className={checkoutClasses.stepRoot}>
            <div className={checkoutClasses.stepContent}>
                <div>
                    <h2>{t('checkout.confirm.title')}</h2>
                </div>
                {
                    error && <Message className={classes.message} message={error} type="error"/>
                }
                <div>
                    <h3>{t('checkout.confirm.details.title')}</h3>
                    <p>{t('checkout.confirm.details.description')}</p>
                    <div className={classes.details}>
                        <div>
                            <span>{t('checkout.confirm.details.name')}</span>
                            <span>{getName(data)}</span>
                        </div>
                        <div>
                            <span>{t('checkout.confirm.details.email')}</span>
                            <span>{email}</span>
                        </div>
                    </div>
                    <div className={classes.label}>
                        <span>{t('checkout.confirm.participants')}</span>
                    </div>
                    <div className={classes.participants}>
                        {
                            isDesktop && (
                                <div className={classes.participant}>
                                    <span className={classes.name}>{t('checkout.confirm.participant.name')}</span>
                                    <span className={classes.distance}>{t('checkout.confirm.participant.distance')}</span>
                                    <span className={classes.medal}>{t('checkout.confirm.participant.medal')}</span>
                                </div>
                            )
                        }
                        {participants.map((participant) => renderParticipant(participant))}
                    </div>
                </div>
                <div className={classes.payment}>
                    <h3>{t('checkout.confirm.payment.title')}</h3>
                    {
                        payment_methods.length > 0
                            ? (
                                <div className={classes.methods}>
                                    {
                                        payment_methods.map((payment_method, index) => (
                                            <Radio
                                                key={index}
                                                name="payment_method"
                                                className={classes.method_radio}
                                                labelClassName={classes.method}
                                                label={payment_method.title}
                                                value={payment_method.code}
                                                checked={payment_method.code === paymentMethod?.code}
                                                onChange={() => onPaymentMethodChange(payment_method)}
                                                required
                                            >
                                                <div className={classes.method_label}>
                                                    <div className={classes.method_info}>
                                                        <span className={classes.method_title}>{payment_method.title}</span>
                                                        <span className={classes.method_description}>{payment_method.description}</span>
                                                    </div>
                                                    {
                                                        payment_method.fee > 0 && (
                                                            <span className={classes.method_fee}>
                                                                + <Price price={payment_method.fee}/>
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            </Radio>
                                        ))
                                    }
                                </div>
                            ) : (
                                <p>{t('checkout.confirm.payment.description')}</p>
                            )
                    }
                </div>
            </div>
            <div className={checkoutClasses.stepButtons}>
                <Button disabled={loading} onClick={goBack} variant="secondary">{t('checkout.steps.previous')}</Button>
                <Button loading={loading} type="submit" variant="primary">{t('checkout.steps.finish')}</Button>
            </div>
        </Form>
    );
};

export default Confirm;
