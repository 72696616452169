import translations from './translations';
import paths from './paths';

class Translator {

    constructor() {
        this.locale = 'nl_NL';
        this.data = translations;
        this.paths = paths;
    }

    setLocale = (locale) => {
        this.locale = locale;
    };

    get = (key, params = null) => {
        if (this.data.hasOwnProperty(this.locale)) {
            const localeData = this.data[this.locale];

            if (localeData.hasOwnProperty(key)) {
                let text = localeData[key];

                if (params) {
                    Object.keys(params).forEach((key) => {
                        text = text.replace(new RegExp(`{{${key}}}`, 'g'), params[key]);
                    });
                    return text;
                } else {
                    return text;
                }
            }
        }

        return key;
    };

    getPath = (path) => {
        if (this.data.hasOwnProperty(this.locale)) {
            const localeData = this.paths[this.locale];

            return localeData[path] ?? path;
        }

        return path;
    }

}

const translator = new Translator();

const setLocale = async (lang) => {
    return translator.setLocale(lang);
};

const t = (key, params = null) => {
    return translator.get(key, params);
};

const getPath = (key) => {
    return translator.getPath(key);
};

export {
    setLocale,
    getPath,
    t
};