import { createContext } from "react";

export default createContext({
    step: null,
    data: {},
    config: {},
    loading: false,
    setData: () => {},
    addData: () => {},
    setConfig: () => {},
    clear: () => {}
});
