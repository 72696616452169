import classes from "./Input.module.css";

const Input = ({ name, label, placeholder, ...restProps }) => {
    placeholder = placeholder || label;

    return (
        <div className={classes.root}>
            <input name={name} placeholder={placeholder} {...restProps} />
            <label htmlFor={name}>{label}</label>
        </div>
    );
};

export default Input;