import { Builder } from '@builder.io/react';
import DOMPurify from 'dompurify';

import classes from './Paragraph.module.css';

const Paragraph = ({ htmlTag, title, text }) => {
    const Tag = htmlTag || 'h2';

    return (
        <div className={classes.root}>
            <Tag className={classes.title}
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(title)}} />
            <div className={classes.text}
                 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(text)}} />
        </div>
    )
};

export const register = () => {
    Builder.registerComponent(Paragraph, {
        name: "Paragraph",
        defaultStyles: {
            marginTop: 0,
            paddingTop: '40px',
            paddingBottom: '55px',
            paddingLeft: '10%',
            paddingRight: '10%'
        },
        inputs: [
            {
                name: "title",
                type: "string",
            },
            {
                name: "text",
                type: "richText",
            },
            {
                name: "htmlTag",
                type: "string",
                defaultValue: "h2",
                enum: [ "h1", "h2", "h3", "h4", "h5", "h6", "span", "strong" ]
            },
        ],
    })
};

export default Paragraph;