import {useContext, useRef} from "react";
import { useNavigate } from "react-router-dom";

import { getPath, t } from "../../../lib/i18n";

import { Button, Form, Input } from "../../../components/ui/form";

import CheckoutContext from "../../../context/checkout";

import checkoutClasses from "../Checkout.module.css";
import classes from "./Details.module.css";

const Details = () => {
    const navigate = useNavigate();
    const { data = {}, addData } = useContext(CheckoutContext);
    const {
        first_name = '',
        middle_name = '',
        last_name = '',
        email = ''
    } = data;

    const onChange = ({ target: { name, value }}) => {
        addData({
            [name]: value
        })
    };

    const onSubmit = ({ valid }) => {
        if (valid) {
            navigate(getPath('/checkout/participants'));
        }
    };

    return (
        <Form onSubmit={onSubmit} className={checkoutClasses.stepRoot}>
            <div className={checkoutClasses.stepContent}>
                <div>
                    <h2>{t('checkout.details.title')}</h2>
                    <p>{t('checkout.details.description')}</p>
                </div>
                <div>
                    <h3>{t('checkout.details.subtitle')}</h3>
                    <div className={classes.name}>
                        <Input
                            name="first_name"
                            label={t('Firstname')}
                            value={first_name}
                            onChange={onChange}
                            required />
                        <Input
                            name="middle_name"
                            label={t('Middlename')}
                            value={middle_name}
                            onChange={onChange} />
                        <Input
                            name="last_name"
                            label={t('Lastname')}
                            value={last_name}
                            onChange={onChange}
                            required />
                    </div>
                    <div className={classes.email}>
                        <Input
                            name="email"
                            label={t('Email')}
                            value={email}
                            onChange={onChange}
                            required />
                    </div>
                </div>
            </div>
            <div className={checkoutClasses.stepButtons}>
                <span/>
                <Button
                    type="submit"
                    variant="primary"
                >{t('checkout.steps.next')}</Button>
            </div>
        </Form>
    );
};

export default Details;
