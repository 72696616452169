import { useEffect, useState } from 'react';
import { builder, Builder, BuilderComponent } from '@builder.io/react';

import useBuilder from '../../hooks/useBuilder';

const BuilderContent = ({
    model = 'page',
    url = null,
    rawContent = null,
    renderLoading = null,
    renderNotFound = null
}) => {
    useBuilder();

    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(false);

    const isLive = !Builder.isEditing && !Builder.isPreviewing;

    useEffect(() => {
        const fetchContent = async () => {
            try {
                setLoading(true);
                const content = await builder.get(model, { url: url }).promise();
                setContent(content);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };

        if (rawContent) {
            setContent(rawContent);
        } else {
            fetchContent();
        }
    }, [model, rawContent, url]);

    if (loading) {
        return renderLoading && renderLoading();
    }

    if (isLive && !content) {
        return renderNotFound && renderNotFound();
    }

    let parsedContent = {};

    if (content) {
        parsedContent = {...content};

        try {
            parsedContent.data = parsedContent.data || {};
            parsedContent.data.blocks = parsedContent.data.blocks || JSON.parse(parsedContent.data.blocksString);
        } catch (e) {
            parsedContent = null;
            console.warn(e);
            console.log('No valid CMS content');
        }
    }

    return (
        <BuilderComponent model={model} content={parsedContent} />
    );
};

export default BuilderContent;