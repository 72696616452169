import { useMemo } from "react";
import { Builder, builder } from "@builder.io/react";

import { env } from "../lib/utils";

import { CarouselComponent } from '@builder.io/widgets/dist/lib/components/Carousel';
import { TabsComponent } from '@builder.io/widgets/dist/lib/components/Tabs';
import { AccordionComponent } from '@builder.io/widgets/dist/lib/components/Accordion';
import { MasonryComponent } from '@builder.io/widgets/dist/lib/components/Masonry';
import { carouselConfig } from '@builder.io/widgets/dist/lib/components/Carousel.config';
import { tabsConfig } from '@builder.io/widgets/dist/lib/components/Tabs.config';
import { accordionConfig } from '@builder.io/widgets/dist/lib/components/Accordion.config';
import { masonryConfig } from '@builder.io/widgets/dist/lib/components/Masonry.config';

import { register as registerLanding } from "../components/cms/builder/landing/Landing";
import { register as registerParagraph } from "../components/cms/builder/paragraph/Paragraph";
import { register as registerImageParagraph } from "../components/cms/builder/image_paragraph/ImageParagraph";
import { register as registerSubscriptionParagraph } from "../components/cms/builder/subscription_paragraph/SubscriptionParagraph";

const useBuilder = () => {
    useMemo(() => {
        // Initialize Builder.io connection
        const apiKey = env('BUILDER_API_KEY');
        builder.init(apiKey);

        // Initialize Builder.io components
        Builder.registerComponent(CarouselComponent, carouselConfig);
        Builder.registerComponent(TabsComponent, tabsConfig);
        Builder.registerComponent(AccordionComponent, accordionConfig);
        Builder.registerComponent(MasonryComponent, masonryConfig);

        // Initialize custom components
        registerLanding();
        registerParagraph();
        registerImageParagraph();
        registerSubscriptionParagraph();

    }, [builder, env, registerLanding, registerParagraph, registerImageParagraph]);
};

export default useBuilder;