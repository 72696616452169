import CheckoutModule from "../modules/checkout/Checkout";
import Layout from "../modules/layout/Layout";

const Checkout = () => {
    return (
        <Layout type="fixed">
            <CheckoutModule />
        </Layout>
    );
};

export default Checkout;
