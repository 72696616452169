import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getPath, t } from "../../../lib/i18n";

import Step from "./step/Step";

import classes from "./Progress.module.css";

const Progress = () => {
    const [current, setCurrent] = useState(1);
    const location = useLocation();
    const navigate = useNavigate();

    const steps = {
        [`${getPath('/checkout/details')}`]: { key: 'details', index: 1, label: t('checkout.steps.details') },
        [`${getPath('/checkout/participants')}`]: { key: 'participants', index: 2, label: t('checkout.steps.participants') },
        [`${getPath('/checkout/confirm')}`]: { key: 'confirm', index: 3, label: t('checkout.steps.confirm') },
        [`${getPath('/checkout/success')}`]: { key: 'success', index: 4, label: t('checkout.steps.success') }
    };

    const isReachable = (index) => (index < current) && (current !== Object.keys(steps).length);

    const goToStep = (path, index) => {
        if (isReachable(index)) {
            navigate(path);
        }
    };

    useEffect(() => {
        const step = steps[location.pathname];

        if (step) {
            setCurrent(step.index);
        }
    }, [location]);

    return (
        <div className={classes.root}>
            {
                Object.entries(steps).map(([ stepPath, { key, index, label } ]) => {
                    return (
                        <Step
                            key={key}
                            index={index}
                            active={current === index}
                            complete={current > index}
                            clickable={isReachable(index)}
                            onClick={() => goToStep(stepPath, index)}
                        >
                            <span>{ label }</span>
                        </Step>
                    )
                })
            }
        </div>
    );
};

export default Progress;
