import { Helmet } from 'react-helmet-async';

import BuilderContent from '../BuilderContent';

import { env } from '../../../lib/utils';

import classes from './Page.module.css';

const Page = ({ cmsData }) => {
    return (
        <>
            <Helmet>
                <title>{cmsData.title || env('PAGE_TITLE')}</title>
                <meta name="title" content={cmsData.metaTitle || cmsData.title || env('META_TITLE')} />
                <meta name="description" content={cmsData.description || env('META_DESCRIPTION')} />
                <meta name="keywords" content={cmsData.keywords || env('META_KEYWORDS')} />
                <meta name="robots" content={cmsData.robots || env('META_ROBOTS')} />
            </Helmet>
            <div className={classes.root}>
                <BuilderContent rawContent={cmsData.content} />
            </div>
        </>
    );
};

export default Page;