import Layout from '../modules/layout/Layout';
import NotFound from './errors/notfound/NotFound';
import Page from '../components/cms/page/Page';

import useCms from '../hooks/useCms';

const Cms = () => {
    const cmsData = useCms();

    return (
        <Layout
            type={(!cmsData || cmsData.fixedLayout) ? 'fixed' : 'auto'}
        >
            {
                (cmsData && cmsData.active)
                    ? <Page cmsData={cmsData} />
                    : <NotFound />
            }
        </Layout>
    );
};

export default Cms;
