import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { getPath } from "./lib/i18n";

import Builder from './pages/Builder';
import Checkout from './pages/Checkout';
import Cms from './pages/Cms';
import Success from './pages/checkout/Success';

import Details from "./modules/checkout/details/Details";
import Participants from "./modules/checkout/participants/Participants";
import Confirm from "./modules/checkout/confirm/Confirm";

const App = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path={getPath('/checkout')} element={<Checkout/>}>
                        <Route index element={<Navigate replace to={getPath('/checkout/details')}/>}/>
                        <Route path={getPath('/checkout/details')} element={<Details/>}/>
                        <Route path={getPath('/checkout/participants')} element={<Participants/>}/>
                        <Route path={getPath('/checkout/confirm')} element={<Confirm/>}/>
                    </Route>
                    <Route path={getPath('/checkout/success')} element={<Success/>}/>
                    <Route path="/builder" element={<Builder/>}/>
                    <Route path="/*" element={<Cms/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;
