import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import classes from '../Errors.module.css';

const Error = () => {
    return (
        <>
            <Helmet
                defaultTitle={'A3Z'}
                titleTemplate={'%s | A3Z'}
            >
                <title>{'Fout opgetreden'}</title>
                <meta name="description" content={''} />
                <meta name="render:status_code" content="500" />
            </Helmet>
            <div className={classes.page}>
                <div className={classes.wrapper}>
                    <span className={classes.title}>
                        Er is iets <span className={classes.warning}>fout</span> gegaan
                    </span>
                    <p className={classes.description}>
                    Er is helaas iets mis gegaan bij het laden van deze pagina. Onze excuses voor het ongemak.<br />
                    Probeer het a.u.b. later opnieuw, of <Link to="/">ga terug naar de homepage</Link>.
                    </p>
                </div>
            </div>
        </>
    );
}

export default Error;
