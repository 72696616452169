import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/css/main.css';
import './assets/css/variables.css';

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
        <App />
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// TODO - Send to analytics
reportWebVitals();
