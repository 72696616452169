import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import classes from '../Errors.module.css';

const NotFound = () => {
    return (
        <>
            <Helmet
                defaultTitle={'A3Z'}
                titleTemplate={'%s | A3Z'}
            >
                <title>{'Pagina niet gevonden'}</title>
                <meta name="description" content={''} />
                <meta name="render:status_code" content="404" />
            </Helmet>
            <div className={classes.page}>
                <div className={classes.wrapper}>
                    <span className={classes.title}>
                        <span className={classes.err_code}>404</span>
                        <span className={classes.err_title}>Pagina niet gevonden</span>
                    </span>
                    <p className={classes.description}>
                    We kunnen de pagina die je zocht helaas niet vinden. Probeer het later opnieuw, of <Link to="/">ga terug naar de homepage</Link>.
                    </p>
                </div>
            </div>
        </>
    );
}

export default NotFound;
