import { Link } from 'react-router-dom';
import classNames from "classnames";

import classes from './Button.module.css';
import skeletonClasses from '../../skeleton/Skeleton.module.css';
import Loader from "../../loader/Loader";

const Button = ({ children, className, innerClassName, href, external, disabled, skeleton, loading, variant, ...props }) => {
    const btnClass = classNames(
        classes.button,
        className,
        classes[variant],
        {
            [`${classes.skeleton}`]: !!skeleton,
            [`${classes.loading}`]: !!loading,
            [`${classes.disabled}`]: loading || disabled,
        }
    );
    const innerClass = classNames(
        classes.inner,
        innerClassName,
        {
            [`${skeletonClasses.loading}`]: !!skeleton,
        }
    );

    let content = (
        <div className={innerClass}>
            <Loader className={classes.loader} size={16} border={2}/>
            {children}
        </div>
    );

    if (href) {
        if (external) {
            content = (
                <a className={innerClass} href={href} rel="noreferrer" target="_blank">{children}</a>
            );
        } else {
            content = (
                <Link className={innerClass} to={href}>{children}</Link>
            );
        }
    }

    return (
        <button className={btnClass} disabled={disabled} {...props}>
            {content}
        </button>
    );
};

export default Button;