import { useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';

import Loader from "../../components/ui/loader/Loader";
import Progress from "./progress/Progress";
import Summary from "./summary/Summary";
import Message from "../../components/ui/message/Message";

import useCreateOrFetchSession from "../../hooks/api/session/useCreateOrFetchSession";
import useStatus from "../../hooks/api/useStatus";
import useSessionId from "../../hooks/api/session/useSessionId";

import { t } from "../../lib/i18n";

import CheckoutContext from "../../context/checkout";

import classNames from "classnames";
import classes from "./Checkout.module.css";

const Checkout = () => {
    const [ data, setData ] = useState({});
    const [ config, setConfig ] = useState({});
    const { loading: sessionLoading, error: sessionError, createOrFetch } = useCreateOrFetchSession();
    const { status: subscriptionEnabled, loading: statusLoading, error: statusError, fetchStatus } = useStatus();
    const { setSessionId } = useSessionId();
    const [ searchParams ] = useSearchParams();

    const loading = sessionLoading || statusLoading;
    const error = !!(sessionError || statusError);

    const contextValue = {
        loading,
        data,
        config,
        setData,
        addData: (newData) => {
            setData({
                ...data,
                ...newData
            })
        },
        setConfig,
        clear: () => {
            setData({});
        }
    };

    useEffect(() => {
        const sessionId = searchParams.get('sessionId');

        if (sessionId) {
            setSessionId(sessionId);
        }

        fetchStatus();
    }, []);

    useEffect(() => {
        if (subscriptionEnabled) {
            createOrFetch().then((data) => {
                const config = data?.config || {};
                const subscription = data?.session?.subscription || {};
                const paymentMethodCode = subscription.payment_method || config.default_payment_method;

                if (paymentMethodCode && config.payment_methods) {
                    const paymentMethod = config.payment_methods.find(
                        (paymentMethod) => paymentMethod.code === paymentMethodCode
                    );

                    if (paymentMethod) {
                        subscription.paymentMethod = paymentMethod;
                    }
                }

                setConfig(config);
                setData(subscription);
            });
        }
    }, [subscriptionEnabled]);

    let content;
    let center = false;

    if (loading) {
        content = <Loader className={classes.loader} />;
        center = true;
    } else if (error) {
        content = <Message message={error} type="error"/>;
    } else if (!subscriptionEnabled) {
        content = subscriptionEnabled === false
            ? <Message message={t('Online subscriptions are currently not available')} type="warning"/>
            : null;
    } else {
        content = <Outlet />;
    }

    return (
        <CheckoutContext.Provider value={contextValue}>
            <div className={classes.root}>
                <div className={classes.container}>
                    <Progress />
                    <div className={classNames(classes.content, center ? classes.center : '')}>
                        {content}
                    </div>
                </div>
                <div className={classes.summary}>
                    <Summary />
                </div>
            </div>
        </CheckoutContext.Provider>
    );
};

export default Checkout;
