import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from "classnames";

import classes from './Header.module.css';
import useWindow from '../../hooks/useWindow';

const Header = ({ type = 'auto' }) => {
    const [visible, setVisible] = useState(false);
    const { getHeight, getScrollHeight } = useWindow();

    const headerClasses = classNames({
        [`${classes.root}`]: true,
        [`${classes.fixed}`]: (type === 'fixed'),
        [`${classes.visible}`]: (type === 'auto' && visible),
        [`${classes.hidden}`]: (type === 'auto' && !visible)
    });

    const handleScroll = (event) => {
        setVisible(getScrollHeight() > getHeight());
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={headerClasses}>
            <div className={classes.title}>
                <Link to="/"><span>Avond <span className={classes.red}>3</span> Daagse Zoeterwoude</span></Link>
            </div>
        </div>
    );
}

export default Header;
