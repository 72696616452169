import { useContext, useState } from "react";

import { t } from "../../../lib/i18n";

import useMediaQuery from "../../../hooks/useMediaQuery";

import CheckoutContext from "../../../context/checkout";

import Price from "../../../components/price/Price";

import classNames from "classnames";
import classes from "./Summary.module.css";

const Summary = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const { match: isMobile } = useMediaQuery('(max-width: 768px)');
    const { config = {}, data = {} } = useContext(CheckoutContext);
    const { distance, has_medal, participants = [], paymentMethod } = data;
    const { allowed_distances = [], price_with_medal = 0, price_without_medal = 0 } = config;

    const onOpen = () => {
        if (isMobile) {
            setIsOpen(!isOpen);
        }
    };

    let countPerDistance = {};
    let countWithMedal = (has_medal === '1') ? 1 : 0;
    let countWithoutMedal = (has_medal === '0') ? 1 : 0;

    allowed_distances.forEach((allowed_distance) => {
        countPerDistance[allowed_distance] = {
            count: (distance === String(allowed_distance)) ? 1 : 0,
            label: allowed_distance + 'km',
            key: allowed_distance
        };
    });

    participants.forEach((participant = {}) => {
        const { distance: participantDistance, has_medal: participantMedal } = participant;

        if (participantDistance) {
            countPerDistance[participantDistance].count++;
        }

        if (participantMedal === "1" || participantMedal === true) {
            countWithMedal++;
        } else if (participantMedal === "0" || participantMedal === false) {
            countWithoutMedal++;
        }
    });

    const totalWithMedal = countWithMedal * price_with_medal;
    const totalWithoutMedal = countWithoutMedal * price_without_medal;
    const paymentFee = paymentMethod?.fee || 0;

    const grandTotal = totalWithMedal + totalWithoutMedal + paymentFee;

    return (
        <div className={classNames(classes.root, isOpen ? classes.open : '')}>
            <div className={classes.heading} onClick={onOpen}>
                <h2>{t('checkout.summary')}</h2>
                {
                    isMobile && (
                        <div className={classes.headingPrice}>
                            <Price price={grandTotal}/>
                        </div>
                    )
                }
            </div>
            <div className={classes.content}>
                <div className={classes.distances}>
                    <h4>{t('checkout.summary.distances')}</h4>
                    {
                        Object.values(countPerDistance).map((distanceData) => (
                            <div key={distanceData.key} className={classes.item}>
                                <span>{distanceData.label}</span>
                                <span>{distanceData.count}x</span>
                            </div>
                        ))
                    }
                </div>
                <div className={classes.medals}>
                    <h4>{t('checkout.summary.medals')}</h4>
                    <div className={classes.item}>
                        <span className={classes.label}>{t('checkout.summary.medals.with')}</span>
                        <span className={classes.count}>{countWithMedal}x</span>
                        <Price className={classes.amount} price={totalWithMedal}/>
                    </div>
                    <div className={classes.item}>
                        <span className={classes.label}>{t('checkout.summary.medals.without')}</span>
                        <span className={classes.count}>{countWithoutMedal}x</span>
                        <Price className={classes.amount} price={totalWithoutMedal}/>
                    </div>
                </div>
                <div className={classes.totals}>
                    <h4>{t('checkout.summary.totals')}</h4>
                    {
                        paymentFee > 0 && (
                            <div className={classes.total}>
                                <span>{t('checkout.summary.totals.fee')}</span>
                                <Price price={paymentFee}/>
                            </div>
                        )
                    }
                    <div className={classes.grand_total}>
                        <span>{t('checkout.summary.totals.grand_total')}</span>
                        <Price price={grandTotal}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Summary;
