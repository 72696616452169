const useViews = () => {
    const locale = 'nl-NL';
    const defaultCurrency = 'EUR';

    const formatPrice = (price = 0, currency) => {
        const formatter = new Intl.NumberFormat(
            locale,
            {
                style: 'currency',
                currency: currency || defaultCurrency
            }
        );

        return formatter.format(price);
    };

    return {
        formatPrice
    }
};

export default useViews;