import { Component } from "react";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import Error from "../../pages/errors/error/Error";

import classNames from "classnames";
import classes from './Layout.module.css';

class Layout extends Component {
    state = {
        hasError: false
    };

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error);
    }

    render() {
        const content = this.state.hasError
            ? <Error />
            : this.props.children;

        const type = this.state.hasError
            ? 'fixed'
            : this.props.type || 'auto';

        return (
            <div className={classNames(classes.root, classes[type] ?? '')}>
                <Header type={type}/>
                <div className={classes.content}>
                    {content}
                </div>
                <Footer type={type}/>
            </div>
        );
    }
}

export default Layout;
