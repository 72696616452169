import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";

import CheckoutContext from "../../../context/checkout";

import { getPath, t } from "../../../lib/i18n";

import useSessionId from "../../../hooks/api/session/useSessionId";

import Progress from "../progress/Progress";

import classes from "./Success.module.css";

const Success = () => {
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const { clear } = useContext(CheckoutContext);
    const { setSessionId } = useSessionId();

    const id = searchParams.get('id');
    const email = searchParams.get('email');

    useEffect(() => {
        if (!id || !email) {
            navigate(getPath('/checkout/details'));
            return;
        }

        clear();
        setSessionId(null);
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Progress />

                <div className={classes.content}>
                    <h2 className={classes.title}>{t('checkout.success.title')}</h2>
                    <div className={classes.description}>
                        <span>{t('checkout.success.description.confirmed')}</span>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    t('checkout.success.description.email', { email })
                                )
                            }} />
                    </div>
                    <div className={classes.id}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    t('checkout.success.description.id', { id })
                                )
                            }} />
                    </div>
                    <div className={classes.description}>
                        <span>{t('checkout.success.description.contact')}</span>
                        <span>{t('checkout.success.description.thanks')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Success;