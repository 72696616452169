import { Builder, Image, withChildren } from '@builder.io/react';
import classNames from "classnames";

import classes from './ImageParagraph.module.css';

const ImageParagraph = ({ alignContent, children, flipped, image, hoverEffect }) => {
    const rootClass = classNames(
        classes.root,
        {
            [`${classes.contentTop}`]: alignContent === 'top',
            [`${classes.contentCenter}`]: alignContent === 'center',
            [`${classes.contentBottom}`]: alignContent === 'bottom',
            [`${classes.flipped}`]: !!flipped,
            [`${classes.hoverEffect}`]: !!hoverEffect,
        }
    );

    return (
        <div className={rootClass}>
            <div className={classes.image}>
                <Image image={image} />
            </div>
            <div className={classes.content}>
                {children}
            </div>
        </div>
    )
};

const ImageParagraphWithBuilderChildren = withChildren(ImageParagraph);

export const register = () => {
    Builder.registerComponent(ImageParagraphWithBuilderChildren, {
        name: "Image Paragraph",
        defaultStyles: {
            marginTop: 0
        },
        defaultChildren: [
            {
                '@type': '@builder.io/sdk:Element',
                defaultStyles: {
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    paddingLeft: '10%',
                    paddingRight: '10%'
                },
                component: {
                    name: 'Paragraph'
                },
            },
        ],
        inputs: [
            {
                name: 'image',
                type: 'file',
                allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
                required: true,
            },
            {
                name: "alignContent",
                type: "string",
                defaultValue: "center",
                enum: [ "top", "center", "bottom" ]
            },
            {
                name: "flipped",
                type: "boolean",
                defaultValue: false
            },
            {
                name: "hoverEffect",
                type: "boolean",
                defaultValue: true
            },
        ]
    })
};

export default ImageParagraph;