import { Builder } from '@builder.io/react';
import DOMPurify from 'dompurify';

import classes from './Landing.module.css';

const Landing = ({ title, subtitle, readMore }) => {
    const scrollDown = () => {
        window.scroll({
            top: window.innerHeight,
            behavior: 'smooth'
        });
    };

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.title}
                     dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(title)}}/>
                <div className={classes.subtitle}
                     dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(subtitle)}}/>
            </div>
            <div className={classes.readMore}
                 onClick={scrollDown}
                 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(readMore)}}/>
        </div>
    );
};

export const register = () => {
    Builder.registerComponent(Landing, {
        name: "Landing Block",
        inputs: [
            {
                name: "title",
                type: "richText",
            },
            {
                name: "subtitle",
                type: "richText",
            },
            {
                name: "readMore",
                type: "richText",
            },
        ],
    })
};

export default Landing;